/** @jsx jsx */
import { jsx } from 'theme-ui'

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import * as headerStyle from './Header.module.css'

const Header = ({ siteTitle, siteSlogan }) => (
  <header className={headerStyle.header} sx={{ bg: 'primary', color: 'background' }}>
    <div className={headerStyle.headerContainer}>
      <h1 style={{ margin: 0 }}>
        <Link to="/" className={headerStyle.headerLink} sx={{ color: 'background' }}>
          {siteTitle}
        </Link>
      </h1>
      <span>{siteSlogan}</span>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header